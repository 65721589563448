/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ukxoyzdamfeprevk4kf73qjy5q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bashejevwjhazfrfbnmkht6fru",
    "aws_cognito_identity_pool_id": "us-east-2:d9a94446-abb0-4d6a-b53e-390b1d69b05d",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_zIoMtJsmP",
    "aws_user_pools_web_client_id": "4g68lmukk5uckhmqai3dsrq48b",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
